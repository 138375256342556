// Package Imports
import { toNumber } from '@framework/utils/lodash'
import { stringToBoolean } from './parse-util'
export const PASSWORD_PROTECTION_AUTH = 'ppa'
export module Cookie {
  export module Key {
    export const CURRENCY = 'Currency'
    export const CURRENT_CURRENCY = 'CurCurrency'
    export const LANGUAGE = 'Language'
    export const COUNTRY = 'Country'
    export const PASSWORD_PROTECTION_AUTH = 'ppa'
    export const LOGIN_POPUP = 'login_popup'
    export const IS_EXISTING_USER = 'isUserAlreadyExist'
    export const IS_BC_USER = 'isBcUser'
    export const IS_KP_SCRIPT_READY = 'isKpReady'
    export const userEncryptedDetails = 'userEncryptedDetails'
  }
}
export const CLIENT_ID = process.env.BETTERCOMMERCE_CLIENT_ID
export const SHARED_SECRET = process.env.BETTERCOMMERCE_SHARED_SECRET
export const CLIENT_ID_STORE = process.env.BETTERCOMMERCE_CLIENT_ID_STORE
export const SHARED_SECRET_STORE = process.env.BETTERCOMMERCE_SHARED_SECRET_STORE
export const DSR_CLIENT_ID = process.env.BETTERCOMMERCE_DSR_CLIENT_ID
export const DSR_SHARED_SECRET = process.env.BETTERCOMMERCE_DSR_SHARED_SECRET
export const BASE_URL = process.env.BETTERCOMMERCE_BASE_URL
export const DSR_BASE_URL = process.env.BETTERCOMMERCE_DSR_BASE_URL
export const DELIVERABILITY_BASE_URL =
  process.env.BETTERCOMMERCE_DELIVERABILITY_BASE_URL
export const AUTH_URL = process.env.BETTERCOMMERCE_AUTH_URL
export const BETTERCMS_BASE_URL = process.env.BETTERCMS_BASE_URL
export const BETTERCMS_API_VERSION = process.env.BETTERCMS_API_VERSION
export const BETTERCMS_API_URL = process.env.BETTERCMS_API_URL
export const ELASTIC_SEARCH_API_URL = process.env.ELASTIC_API_URL
export const ELASTIC_TOKEN = process.env.ELASTIC_TOKEN
export const BETTER_REVIEW_BASE_URL = process.env.BETTER_REVIEW_BASE_URL
export const BETTER_REVIEW_API_VERSION = process.env.BETTER_REVIEW_API_VERSION
export const BETTER_REVIEW_API_URL = process.env.BETTER_REVIEW_API_URL
export const CIPHER_ENCRYPTION_KEY = process.env.CIPHER_ENCRYPTION_KEY
export const IS_TEST_PAYMENT_ENABLED_ON_LIVE =
  process.env.IS_TEST_PAYMENT_ENABLED_ON_LIVE
export const TEST_PAYMENT_AMOUNT = process.env.TEST_PAYMENT_AMOUNT
  ? toNumber(process.env.TEST_PAYMENT_AMOUNT)
  : 0.0
export const JUSPAY_PAY_METHOD_SYSTEM_NAME =
  process.env.JUSPAY_PAY_METHOD_SYSTEM_NAME
export const PPG_CH_SYSTEM_NAME =
  process.env.PPG_CH_SYSTEM_NAME
export const GOKWIK_COD_METHOD_SYSTEM_NAME =
  process.env.GOKWIK_COD_METHOD_SYSTEM_NAME
export const JUSPAY_MERCHANT_ID = process.env.JUSPAY_MERCHANT_ID
export const PPG_CHECKOUT_PAYMENT_PROVIDER_MERCHANT_ID = process.env.PPG_CHECKOUT_PAYMENT_PROVIDER_MERCHANT_ID
export const JUSPAY_V3_JS = process.env.JUSPAY_V3_JS
export const JUSPAY_OFFERS_APPLY_COUPON_ENABLED = stringToBoolean(
  process.env.JUSPAY_OFFERS_APPLY_COUPON_ENABLED
)
export const GOKWIK_RTO_ENVIRONMENT = process.env.GOKWIK_RTO_ENVIRONMENT
export const GOKWIK_RTO_SDK_JS = process.env.GOKWIK_RTO_SDK_JS
export const USE_MWEB_INTENT_FOR_MOBILE_WALLETS = stringToBoolean(
  process.env.USE_MWEB_INTENT_FOR_MOBILE_WALLETS
)
export const OMNILYTICS_DISABLED = stringToBoolean(
  process.env.OMNILYTICS_DISABLED
)
export const GA4_DISABLED = stringToBoolean(process.env.GA4_DISABLED)
export const APP_FLYER_DISABLED = stringToBoolean(
  process.env.NEXT_PUBLIC_APP_FLYER_DISABLED
)
export const MOENGAGE_DISABLED = stringToBoolean(process.env.MOENGAGE_DISABLED)
export const GA4_MEASUREMENT_ID = process.env.GA4_MEASUREMENT_ID
export const MOENGAGE_APP_ID = process.env.MOENGAGE_APP_ID
export const DACLUB_PROMO_CODE = process.env.DACLUB_PROMO_CODE
export const APPLY_JUSPAY_OFFERS_DISABLED = stringToBoolean(
  process.env.APPLY_JUSPAY_OFFERS_DISABLED
)
export const JUSPAY_OFFERS_PROMO_CODE = process.env.JUSPAY_OFFERS_PROMO_CODE
export const DACLUB_PROMO_CODE_VALIDITY = process.env.DACLUB_PROMO_CODE_VALIDITY
export const JUSPAY_TXN_START_URL_PREFIX =
  process.env.JUSPAY_TXN_START_URL_PREFIX
export const AGGREGATE_COD_ADDITIONAL_CHARGES_IN_TOTAL = stringToBoolean(
  process.env.AGGREGATE_COD_ADDITIONAL_CHARGES_IN_TOTAL
)
export const STATIC_PRODUCTS_BUILD_COUNT = toNumber(
  process.env.STATIC_PRODUCTS_BUILD_COUNT
)
export const PDP_VARIANT_SELECTION_REDIRECT_ENABLED = stringToBoolean(
  process.env.PDP_VARIANT_SELECTION_REDIRECT_ENABLED
)
export const SITEMAP_BASE_URL = process.env.SITEMAP_BASE_URL
export const LICENSE_STRICH = process.env.LICENSE_STRICH
export const CDN_BASE_URL = process.env.CDN_BASE_URL
export const PRODUCT_IMAGE_CDN_URL = process.env.PRODUCT_IMAGE_CDN_URL

export const OMS_BASE_URL = process.env.OMS_BASE_URL
export const ORG_ID = process.env.NEXT_PUBLIC_ORG_ID

// GOKWIK
export const NEXT_PUBLIC_IS_GOKWIK_CHECKOUT_ENABLED = stringToBoolean(
  process.env.NEXT_PUBLIC_IS_GOKWIK_CHECKOUT_ENABLED
)
export const GOKWIK_BASE_URL = process.env.NEXT_PUBLIC_GOKWIK_BASE_URL
export const NEXT_PUBLIC_GOKWIK_APP_ID = process.env.NEXT_PUBLIC_GOKWIK_APP_ID
export const NEXT_PUBLIC_GOKWIK_APP_SECRET_ID =
  process.env.NEXT_PUBLIC_GOKWIK_APP_SECRET

//PPG
export const GOOGLE_MAP_KEY = process.env.GOOGLE_MAP_KEY
export const IS_PPG_CHECKOUT_ENABLED = stringToBoolean(
  process.env.IS_PPG_CHECKOUT_ENABLED
)
export const PPG_MAX_LIMIT = process.env.PPG_MAX_LIMIT

// SWOP STORE
export const IS_SWOP_STORE_ENABLED = stringToBoolean(
  process.env.NEXT_IS_SWOP_STORE_ENABLED
)
export const IS_ENABLE_CART_REDIRECTION_FROM_ADDTOCART = stringToBoolean(
  process.env.ENABLE_CART_REDIRECTION_FROM_ADDTOCART
)
export const IS_BESTSELLER_VERTICAL_VISIBLE = stringToBoolean(
  process.env.IS_BESTSELLER_VERTICAL_VISIBLE
)

export const IS_TOP_BANNER_HOME_ENABLED = stringToBoolean(
  process.env.IS_TOP_BANNER_HOME_ENABLED
)
export const IS_TOP_BANNER_PDP_ENABLED = stringToBoolean(
  process.env.IS_TOP_BANNER_PDP_ENABLED
)
export const IS_TOP_BANNER_PLP_ENABLED = stringToBoolean(
  process.env.IS_TOP_BANNER_PLP_ENABLED
)
export const IS_TOP_BANNER_LP_ENABLED = stringToBoolean(
  process.env.IS_TOP_BANNER_LP_ENABLED
)

export const IS_EMAIL_FIELD_ENABLED = stringToBoolean(
  process.env.IS_EMAIL_FIELD_ENABLED
)

export const SWOP_STORE_SHOP_ID = process.env.NEXT_SWOP_STORE_SHOP_ID
export const SWOP_STORE_BANNER_ID = process.env.NEXT_SWOP_STORE_BANNER_ID

//cart Coupon setting
export const DISABLE_SHOW_COUPONS_BELOW = stringToBoolean(
  process.env.DISABLE_SHOW_COUPONS_BELOW
)
export const DISABLE_SHOW_COUPONS_TOP = stringToBoolean(
  process.env.DISABLE_SHOW_COUPONS_TOP
)
export const HIDE_OLD_COUPONS_LAYOUT = stringToBoolean(
  process.env.HIDE_OLD_COUPONS_LAYOUT
)
export const HIDE_EXTRA_PRODUCT_OFFER_CARD = stringToBoolean(
  process.env.HIDE_EXTRA_PRODUCT_OFFER_CARD
)
export const FREQUENTLY_BOUGHT_TOGETHER_TOP_BTN = stringToBoolean(
  process.env.FREQUENTLY_BOUGHT_TOGETHER_TOP_BTN
)
export const FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN = stringToBoolean(
  process.env.FREQUENTLY_BOUGHT_TOGETHER_BTM_BTN
)

export const FREQUENTLY_BOUGHT_TOGETHER_VIEW_ALL_BTN = stringToBoolean(
  process.env.FREQUENTLY_BOUGHT_TOGETHER_VIEW_ALL_BTN
)
export const HIDE_TOTAL_SAVING_MOBILE_HEADER = stringToBoolean(
  process.env.HIDE_TOTAL_SAVING_MOBILE_HEADER
)

export const COUPON_IMG_BASE_URL = process.env.COUPON_IMG_BASE_URL
export const KP_MERCHANT_SCRIPT_BASE_URL = process.env.KP_MERCHANT_SCRIPT_BASE_URL
export const KP_MERCHANT_INFO = process.env.KP_MERCHANT_INFO
export const IMAGE_ASSET_BASE_URL = process.env.IMAGE_ASSET_BASE_URL
export const OTHER_ASSET_BASE_URL = process.env.OTHER_ASSET_BASE_URL
export const CIPHER_RSA_PUBLIC_KEY = process.env.CIPHER_RSA_PUBLIC_KEY
