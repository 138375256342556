// Package Imports
import axios from '@services/axiosInterceptorInstance'
import { toNumber } from '@framework/utils/lodash'

// Other Imports
import { JusPay, PaymentMethodType } from '@components/utils/payment-constants'
import { JUSPAY_API, NEXT_CANCEL_ORDER, NEXT_CONFIRM_ORDER, NEXT_POST_PAYMENT_RESPONSE, NEXT_UPDATE_BASKET_CUSTOM_INFO, UtmCookieKey } from '@components/utils/constants'
import { matchStrings, stringToBoolean } from './parse-util'
import Cookies from 'js-cookie'
import { IOffersInput } from '@components/checkout/helper'
import { getCampaignInfo } from './app-util'
import { ILogger, LOGGER_TYPE, storeLogs } from '@components/utils/logger'

export interface ICreateJusPayCustomer {
  readonly mobileCountryCode: string
  readonly user?: any
}

export const getReferer = (origin: string) => {
  let referer
  if (origin) {
    if (origin.startsWith('http://')) {
      referer = origin.replace('http://', '')
    } else if (origin.startsWith('https://')) {
      referer = origin.replace('https://', '')
    }

    referer = referer?.substring(0, referer?.indexOf('/'))
    referer = `${origin.startsWith('https://') ? 'https' : 'http'}://${referer}`
    if (referer.endsWith('/')) {
      referer = referer.substring(0, referer.length - 1)
    }
  }
  return referer
}

export const getJusPayPaymentMethods = async ({
  authToken,
  userId,
}: {
  authToken: string
  userId: string
}) => {
  const params = {
    ...JusPay.RequestParams.GET_PAYMENT_METHODS,
  }
  const paymentMethodsResult = await axios.get(JUSPAY_API, {
    params: params,
    headers: {
      authToken,
      userId,
    },
  })
  return paymentMethodsResult
}

export const getJusPayOffers = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const paymentMethodsResult = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.GET_OFFERS,
    headers: {
      authToken,
      userId,
    },
  })
  return paymentMethodsResult
}

export const getJusPayCardBinInfo = async (
  cardBin: string,
  authToken: string,
  userId: string
) => {
  const params = {
    ...JusPay.RequestParams.GET_CARD_BIN,
    ...{
      cardBin: cardBin,
    },
  }
  const cardBinResult = await axios.get(JUSPAY_API, {
    params: params,
    headers: {
      authToken,
      userId,
    },
  })
  return cardBinResult
}

export const createJusPayCustomerIfNotExists = async (
  input: ICreateJusPayCustomer,
  authToken: string,
  userId: string
) => {
  const { mobileCountryCode, user } = input
  let customerResult: any
  const paymentCustomerId = user?.userId
  const params = {
    ...JusPay.RequestParams.GET_CUSTOMER,
    ...{
      id: encodeURIComponent(paymentCustomerId),
    },
  }
  customerResult = await axios.get(JUSPAY_API, {
    params: params,
    headers: {
      authToken,
      userId,
    },
  })

  if (!customerResult?.data) {
    // Customer not found
    //if (customerResult?.data?.hasError && customerResult?.data?.error?.response_status === 404) { // Customer not found

    const data = {
      object_reference_id: paymentCustomerId,
      mobile_number: user?.username,
      email_address: user?.email,
      first_name: user?.firstName,
      last_name: user?.lastName,
      mobile_country_code: mobileCountryCode, //BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
    }
    const createCustomerResult = await axios.post(JUSPAY_API, data, {
      params: JusPay.RequestParams.CREATE_CUSTOMER,
      headers: {
        authToken,
        userId,
      },
    })
    return createCustomerResult
  }
  return customerResult
  //console.log(customerResult);
}

export const createStorefrontOrder = async (
  basketId: string,
  data: any,
  isChannelStore: boolean
) => {
  // Saving captured data of UTM to basket
  // const utm = Cookies.get(UtmCookieKey)
  // let CustomInfo2 = ''
  // if (utm) {
  //   CustomInfo2 = getCampaignInfo(utm)
  // }

  // if (CustomInfo2) {
  //   axios.post(
  //     NEXT_UPDATE_BASKET_CUSTOM_INFO,
  //     {
  //       basketId,
  //       CustomInfo2,
  //       LineInfo: [],
  //     }
  //   )
  // }

  let dataInfo = {}
  if (isChannelStore) {
    const addressInfo: any = Cookies?.get('storeDetails')
    const storeDetails = addressInfo && JSON.parse(addressInfo)
    dataInfo = {
      basketId: data?.basketId,
      customerId: data?.customerId,
      refStoreId: storeDetails?.id,
      storeAddress: storeDetails,
      Payment: data?.Payment,
      selectedShipping: data?.selectedShipping,
      orderType: 12,
      billingAddress: {
        address1: storeDetails?.address1,
        address2: storeDetails?.address2,
        address3: storeDetails?.address3,
        city: storeDetails?.city,
        companyName: storeDetails?.name,
        country: storeDetails?.country,
        countryCode: storeDetails?.countryCode,
        firstName: data?.billingAddress?.firstName,
        lastName: data?.billingAddress?.lastName,
        mobileNo: data?.billingAddress?.mobileNo,
        phoneNo: data?.billingAddress?.phoneNo,
        postCode: storeDetails?.postCode,
        state: storeDetails?.state,
        title: 'PickPackGo',
        isConsentSelected: true,
        label: 'DAStore',
      },
      shippingAddress: {
        address1: storeDetails?.address1,
        address2: storeDetails?.address2,
        address3: storeDetails?.address3,
        city: storeDetails?.city,
        companyName: storeDetails?.name,
        country: storeDetails?.country,
        countryCode: storeDetails?.countryCode,
        firstName: data?.shippingAddress?.firstName,
        lastName: data?.shippingAddress?.lastName,
        isConsentSelected: true,
        label: 'DAStore',
        mobileNo: data?.shippingAddress?.mobileNo,
        phoneNo: data?.shippingAddress?.phoneNo,
        postCode: storeDetails?.postCode,
        state: storeDetails?.state,
        title: 'PickPackGo',
      },
    }
  }

  const apiStartTime = new Date().getTime()
  const { data: orderResponse }: any = await axios.post(NEXT_CONFIRM_ORDER, {
    basketId,
    model: isChannelStore ? dataInfo : data,
    isChannelStore: isChannelStore ? true : false,
  })
  const apiEndTime = new Date().getTime()
  const apiLog: ILogger = {
    machineId: '',
    fetcherResponseTime: apiEndTime - apiStartTime,
    fetcherStartTime: apiStartTime,
    fetcherEndTime: apiEndTime,
    message: 'convert_order_api',
    apiPath: NEXT_CONFIRM_ORDER,
    type: LOGGER_TYPE.SUCCESS,
    pageType: '',
    level: LOGGER_TYPE.INFO,
  }
  await storeLogs(apiLog)
  return orderResponse
}

export const cancelStorefrontOrder = async (orderId: string) => {
  const { data: orderResponse }: any = await axios.post(NEXT_CANCEL_ORDER, {
    id: orderId,
  })
  return orderResponse
}

export const tokenizeJusPayCard = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: tokenizeCardResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.TOKENIZE_CARD,
    headers: {
      authToken,
      userId,
    },
  })
  return tokenizeCardResult
}

export const saveJusPayCard = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: saveCreditDebitCardResult } = await axios.post(
    JUSPAY_API,
    data,
    {
      params: JusPay.RequestParams.SAVE_CARD,
      headers: { authToken, userId },
    }
  )
  return saveCreditDebitCardResult
}

export const deleteJusPayCard = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: deleteCardResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.DELETE_CARD,
    headers: {
      authToken,
      userId,
    },
  })
  return deleteCardResult
}

export const listJusPayCards = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: listCardsResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.LIST_CARDS,
    headers: {
      authToken,
      userId,
    },
  })
  return listCardsResult
}

export const createJusPayCreditDebitCardTransaction = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: creditDebitCardResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.CREDIT_DEBIT_CARD_PAYMENT,
    headers: {
      authToken,
      userId,
    },
  })
  return creditDebitCardResult
}

export const createNetbankingTransaction = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: creditDebitCardResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.NETBANKING_PAYMENT,
    headers: {
      authToken,
      userId,
    },
  })
  return creditDebitCardResult
}

export const createWalletTransaction = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: walletResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.WALLET_PAYMENT,
    headers: {
      authToken,
      userId,
    },
  })
  return walletResult
}

export const createUPIIntentTransaction = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: upiIntentResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.UPI_INTENT_PAYMENT,
    headers: {
      authToken,
      userId,
    },
  })
  return upiIntentResult
}

export const getJusPayOrder = async (
  orderId: string,
  authToken: string,
  userId: string
) => {
  const { data: orderResult } = await axios.get(JUSPAY_API, {
    params: {
      ...JusPay.RequestParams.GET_ORDER,
      ...{
        id: orderId,
      },
      headers: {
        authToken,
        userId,
      },
    },
  })
  return orderResult
}

export const createJusPayOrder = async ({
  txnOrderId,
  orderId,
  productId,
  customerId,
  currencyCode,
  customerEmail,
  customerPhone,
  billingAddress,
  shippingAddress,
  twoLetterIsoCode,
  mobileCountryCode,
  applyOffersDisabled,
  jusPayDetails,
  methodId,
  methodName,
  paymentMethodInfo,
  savePspInfo,
  optionalFields = {},
  authToken = '',
  userId = '',
}: any) => {
  const data = {
    txnOrderId,
    orderId,
    productId,
    customerId,
    currencyCode,
    customerEmail,
    customerPhone,
    billingAddress,
    shippingAddress,
    twoLetterIsoCode,
    mobileCountryCode,
    applyOffersDisabled,
    jusPayDetails,
    methodId,
    methodName,
    paymentMethodInfo,
    savePspInfo,
  }
  //console.log("data", data);
  const orderResult = await axios.post(
    JUSPAY_API,
    { ...data, ...optionalFields },
    {
      params: JusPay.RequestParams.CREATE_ORDER,
      headers: {
        authToken,
        userId,
      },
    }
  )
  return orderResult
}

export const updateJusPayOrder = async ({
  orderId,
  amount,
  authToken = '',
  userId = '',
}: any) => {
  const data = {
    orderId,
    amount,
  }
  const orderResult = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.UPDATE_ORDER,
    headers: {
      authToken,
      userId,
    },
  })
  return orderResult
}

export const confirmPayment = async (data: any) => {
  return await axios.post(NEXT_POST_PAYMENT_RESPONSE, data)
}

export const verifyVPA = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const fetcherStartTime = new Date().getTime()
  const { data: verifyAndPayViaUPIResult } = await axios.post(
    JUSPAY_API,
    data,
    {
      params: JusPay.RequestParams.VERIFY_VPA,
      headers: {
        authToken,
        userId,
      },
    }
  )
  const fetcherEndTime = new Date().getTime()
  const log: ILogger = {
    machineId: '',
    fetcherResponseTime: fetcherEndTime - fetcherStartTime,
    fetcherStartTime,
    fetcherEndTime,
    message: 'juspay_upi_validation_api',
    apiPath: JUSPAY_API,
    type: LOGGER_TYPE.SUCCESS,
    pageType: '',
    level: LOGGER_TYPE.INFO,
  }
  await storeLogs(log)
  return verifyAndPayViaUPIResult
}

export const collectUPI = async (
  data: any,
  authToken: string,
  userId: string
) => {
  const { data: collectUPIResult } = await axios.post(JUSPAY_API, data, {
    params: JusPay.RequestParams.PAY_VIA_UPI,
    headers: {
      authToken,
      userId,
    },
  })
  return collectUPIResult
}

export const isCardMethodSupported = (
  paymentMethods: Array<{
    payment_method: string
    payment_method_type: string
    description: string
    juspay_bank_code?: string
  }>,
  method: string
): boolean => {
  const cardPaymentMethod = paymentMethods?.find(
    (m: any) =>
      m?.payment_method_type === PaymentMethodType.CARD &&
      matchStrings(m?.payment_method, method, true)
  )
  return cardPaymentMethod ? true : false
}

export const isNetBankingMethodSupported = (
  paymentMethods: Array<{
    payment_method: string
    payment_method_type: string
    description: string
    juspay_bank_code?: string
  }>,
  method: string
): boolean => {
  const cardPaymentMethod = paymentMethods?.find(
    (m: any) =>
      m?.payment_method_type === PaymentMethodType.NETBANKING &&
      matchStrings(m?.payment_method, method, true)
  )
  return cardPaymentMethod ? true : false
}

export const isWalletMethodSupported = (
  paymentMethods: Array<{
    payment_method: string
    payment_method_type: string
    description: string
    juspay_bank_code?: string
  }>,
  method: string
): boolean => {
  const cardPaymentMethod = paymentMethods?.find(
    (m: any) =>
      m?.payment_method_type === PaymentMethodType.WALLET &&
      matchStrings(m?.payment_method, method, true)
  )
  return cardPaymentMethod ? true : false
}

export const getPreferredPaymentMethod = (data: any) => {
  if (data?.defaultPaymentMethod) {
    return {
      key: data?.defaultPaymentIdentifier,
      method: data?.defaultPaymentMethod,
      type: data?.pspInformation,
    }
  }
  /*if (preferredPaymentMethod) {
        const categories = preferredPaymentMethod?.split("|");
        if (categories?.length > 1) {
            return {
                method: categories[0].trim(),
                type: categories[1].trim(),
            };
        } else {
            return {
                method: preferredPaymentMethod,
                type: "",
            };
        }
    }*/
  return undefined
}

export const queryApplicableOffer = (
  data: IOffersInput,
  queryOffersCallback: Function | undefined
) => {
  if (data && queryOffersCallback) {
    queryOffersCallback(data)
  }
}

export const filterJusPayOffers = (
  paymentOffers: any,
  orderAmount?: number
) => {
  if (orderAmount) {
    return paymentOffers?.offers?.filter(
      (x: any) =>
        matchStrings(x?.status, JusPay.Offers.ELIGIBLE_OFFER_STATUS, true) &&
        toNumber(x?.offer_rules?.amount?.min_order_amount) <= orderAmount &&
        stringToBoolean(x?.ui_configs?.auto_apply)
    )
  }
  return paymentOffers?.offers?.filter(
    (x: any) =>
      matchStrings(x?.status, JusPay.Offers.ELIGIBLE_OFFER_STATUS, true) &&
      stringToBoolean(x?.ui_configs?.auto_apply)
  )
}
