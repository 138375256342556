// Package Imports
import axios from '@services/axiosInterceptorInstance'
import axiosTemp from 'axios'
import { groupBy, isEmpty, toNumber } from '@framework/utils/lodash'
import { v4 as uuid } from 'uuid'
import { encrypt } from '@framework/utils/cipher'

// Other Imports
import {
  DataSubmit,
  ISubmitStateInterface,
} from '@commerce/utils/use-data-submit'
import {
  EmptyGuid,
  INFRA_LOG_ENDPOINT,
  Messages,
  NEXT_GET_CUSTOMER,
  NEXT_PINCODE_LOOKUP,
  UtmCookieKey,
  AppsFlyerParams,
  UtmAppsFlyerCookieKey,
  EmptyString,
  NEXT_GET_SIZE_CHART,
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  CHECKOUT_FLOW_TYPES,
  FirebaseKeys,
  NEXT_DELIVERABILITY_ETA,
  NEXT_APPLY_PROMOTION,
} from '@components/utils/constants'
import { LocalStorage } from '@components/utils/payment-constants'
import { getPreferredPaymentMethod, updateJusPayOrder } from './juspay-util'
import { matchStrings, numberToString, stringToBoolean, tryParseJson } from './parse-util'
import UpiPSPs from '@components/payments/upiPSPs.json'
import { ILogRequestParams } from '@framework/api/operations/log-request'
import fetcher from '@framework/fetcher'
import Cookies from 'js-cookie'
import setSessionIdCookie from '@components/utils/setSessionId'
import { decrypt, getSHA256Hash } from './cipher'
import { isMobileOnly } from 'react-device-detect'
import Router from 'next/router'
import { getItem, removeItem, setItem } from '@components/utils/localStorage'
import { getSessionItem, setSessionItem } from '@components/utils/sessionStorage'
import { IPerformanceEntry } from 'hooks/utm.modal'
import crypto from 'crypto'
import { DAYS_15, DAYS_30 } from '@components/utils/textVariables'
import { IImage } from '@components/utils/model/product.model'
import {
  IS_TOP_BANNER_HOME_ENABLED,
  IS_TOP_BANNER_PDP_ENABLED,
  IS_TOP_BANNER_PLP_ENABLED,
  IS_TOP_BANNER_LP_ENABLED,
  Cookie,
  OTHER_ASSET_BASE_URL,
  IS_EMAIL_FIELD_ENABLED,
} from './constants'
import { getUserNumber } from '@components/account/loginHelper'

const path = require('path')

declare const MobileDetect: any

interface PolicyImagesObject {
  value?: string
  // Add other properties if necessary
}

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ')
}

export const findByFieldName = (fields: Array<any>, fieldName: string) => {
  if (fields?.length) {
    return fields?.find((x) => matchStrings(x?.name, fieldName, true))
  }
  return null
}

export const parseFullName = (
  fullName: string
): { firstName: string | ' '; lastName: string | ' ' } => {
  if (fullName) {
    if (fullName.trim().indexOf(' ') == -1) {
      return {
        firstName: fullName,
        lastName: ' ',
      }
    } else {
      const index = fullName.indexOf(' ')
      const firstName = fullName.trim().substring(0, index).trim()
      const lastName = fullName
        .trim()
        .substring(index + 1)
        .trim()
      return { firstName: firstName, lastName: lastName ?? ' ' }
    }
  }
  return { firstName: ' ', lastName: ' ' }
}

export const parseJusPayConfig = (configSettings: any) => {
  if (configSettings?.length) {
    const sandboxUrl = configSettings?.find(
      (x: any) => x?.key === 'TestUrl'
    )?.value
    const liveUrl = configSettings?.find(
      (x: any) => x?.key === 'ProductionUrl'
    )?.value
    const useSandbox = configSettings?.find(
      (x: any) => x?.key === 'UseSandbox'
    )?.value
    const config = {
      merchantId: configSettings?.find((x: any) => x?.key === 'AccountCode')
        ?.value,
      apiKey: `${
        configSettings?.find((x: any) => x?.key === 'Signature')?.value
        }:`,
      baseUrl: stringToBoolean(useSandbox) ? sandboxUrl : liveUrl,
    }
    return config
  }

  return null
}

export const getOrderId = (order: any) => {
  return `${order?.orderNo}-${order?.id}`
}

export const submitData = (dispatch: any, type: number, id?: string) => {
  if (dispatch) {
    if (!id) {
      dispatch({ type: DataSubmit.SET_SUBMITTING, payload: type })
    } else {
      dispatch({
        type: DataSubmit.SET_SUBMITTING_OPTIONAL_ID,
        payload: { type: type, id: id ?? '' },
      })
    }
  }
}

export const errorSubmitData = (
  dispatch: any,
  error: any,
  useAPIErrorMessage?: boolean
) => {
  if (dispatch) {
    dispatch({
      type: DataSubmit.ERROR,
      payload: { ...error, ...{ useAPIError: useAPIErrorMessage ?? false } },
    })
  }
}

export const resetSubmitData = (dispatch: any) => {
  if (dispatch) {
    dispatch({ type: DataSubmit.RESET_SUBMITTING })
  }
}

export const resetErrorSubmitData = (dispatch: any) => {
  if (dispatch) {
    dispatch({ type: DataSubmit.RESET_ERROR })
  }
}

export const submittingClassName = (
  state: ISubmitStateInterface,
  type: number,
  id?: string | undefined
) => {
  return state &&
    state?.isSubmitting &&
    state?.submitSource === type &&
    (!state?.id || (state && state?.id && state?.id === (id ?? '')))
    ? 'opacity-50 cursor-not-allowed'
    : ''
}

export const removePrecedingSlash = (value: string) => {
  if (value && value.indexOf('/') === 0) {
    return value.substring(1)
  }
  return value
}

export const removeSucceedingSlug = (value: string) => {
  if(!value) return
  if (value && value.indexOf('/') === 0) {
    value.substring(1)
  }
  const parts = value.split("/");
  const newPath = parts.slice(0, parts.length - 1).join("/");
  return newPath
}

export const getOrderInfo = async () => {
  const convertedOrder: any = localStorage?.getItem(
    LocalStorage.Key.CONVERTED_ORDER
  )
  if (convertedOrder) {
    const decryptedOrder = decrypt(convertedOrder)
    if (decryptedOrder) {
      return JSON.parse(decryptedOrder)
    }
  }
  return undefined
}

export const updateOrderDetails = async (amount: number, orderInfo?: any) => {
  const savedOrder = !orderInfo ? await getOrderInfo() : orderInfo
  if (amount > 0 && savedOrder?.jusPayOrder?.orderId) {
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const user = localStorage?.getItem('user')
    const userDetails = user ? JSON.parse(user) : {}
    const userId = userDetails?.userId
    const { data: updateOrderResult }: any = await updateJusPayOrder({
      orderId: savedOrder?.jusPayOrder?.orderId,
      amount,
      authToken,
      userId,
    })
    return updateOrderResult
  }
  return undefined
}

export const getErrorMessage = (error: {
  status: string
  error_message: string
  error_code: string
  response_status?: number
  useAPIError?: boolean
}): string | undefined => {
  const errorMsg = Messages.Errors['GENERIC_ERROR']
  if (error?.useAPIError) {
    return error?.error_message
  }
  if (error?.error_code) {
    if (Messages.Errors[error?.error_code?.toUpperCase()]) {
      return Messages.Errors[error?.error_code?.toUpperCase()]
    }
  }
  return errorMsg
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getLineItemSizes = (
  variantProductsAttributes: Array<any>
): Array<string> => {
  const sizeAttributeValues = variantProductsAttributes?.find((x: any) =>
    matchStrings(x?.fieldCode, 'clothing.size', true)
  )
  if (sizeAttributeValues?.fieldValues?.length) {
    return sizeAttributeValues?.fieldValues?.map((x: any) => x.fieldValue)
  }
  return []
}

export const getLineItemSize = (
  sizeValue: string,
  variantProductsAttributes: Array<any>
): string => {
  if (sizeValue) {
    const allowedSizes = getLineItemSizes(variantProductsAttributes)
    if (allowedSizes?.includes(sizeValue)) {
      return sizeValue
    }
  }
  return ''
}

export const getUPIAppPackageName = (appKey: string) => {
  if (UpiPSPs?.data && UpiPSPs?.data?.length) {
    const upiPSP: any = UpiPSPs.data.find((x: any) =>
      matchStrings(x?.key, appKey, true)
    )
    if (upiPSP?.appBundle) {
      let type = new MobileDetect(window?.navigator.userAgent)
      if (type.os() === 'iOS') {
        return upiPSP?.appBundle?.ios
      } else if (type.os() === 'AndroidOS') {
        return upiPSP?.appBundle?.android
      }
    }
  }
  return ''
  //return (Wallets?.wallets ?? []).find((x: any) => matchStrings(x?.key, appKey, true))?.upiAppPackage;
}

export const getMinMax = (list: Array<any>, dependantProp: string) => {
  let lowest = Number.POSITIVE_INFINITY
  let highest = Number.NEGATIVE_INFINITY
  var tmp
  for (var i = list.length - 1; i >= 0; i--) {
    tmp = list[i][dependantProp]
    if (tmp < lowest) lowest = tmp
    if (tmp > highest) highest = tmp
  }

  return {
    min: lowest,
    max: highest,
  }
}

export const pincodeLookup = async (pincode: string) => {
  if (pincode.length == 6) {
    const { data: pinCodeLookupResult }: any = await axios.post(
      NEXT_PINCODE_LOOKUP,
      { postCode: pincode }
    )
    return pinCodeLookupResult?.result
  }
}

export const replaceAll = (text: string, replacement: string, regExp: any) => {
  if (text) {
    return text.replace(regExp, replacement)
  }
  return text
}

export const sanitizeDateOrMonth = (value: any) => {
  if (value) {
    if (toNumber(value.toString()) < 10) {
      return `0${toNumber(value.toString())}`
    }
  }
  return value
}

export const logPaymentRequest = async (
  {
    headers = {},
    data = {},
    cookies = {},
    pageUrl,
    objectId = '',
    paymentGatewayId = 0,
    userId = '',
    userName = '',
  }: any,
  message: string
) => {
  const logData: ILogRequestParams = {
    ipAddress: '',
    logLevelId: 20,
    objectId: objectId ?? uuid(),
    pageUrl: pageUrl,
    requestData: JSON.stringify(data),
    shortMessage: message,
    fullMessage: JSON.stringify(headers),
    additionalinfo1: JSON.stringify(cookies),
    paymentGatewayId: paymentGatewayId,
    userId: userId,
    userName: userName,
  }

  const url = `${INFRA_LOG_ENDPOINT}/payment-log`
  try {
    const logResult: any = await fetcher({
      url,
      data: logData,
      method: 'POST',
      headers: {
        DomainId: process.env.NEXT_PUBLIC_DOMAIN_ID,
      },
    })
    return logResult
  } catch (error: any) {
    console.log(error, 'err')
    // throw new Error(error.message)
  }
  return null
}

export const logActivityRequest = async (
  {
    headers = {},
    data = {},
    cookies = {},
    pageUrl,
    objectId = '',
    userId = '',
    userName = '',
  }: any,
  message: string
) => {
  const logData: ILogRequestParams = {
    ipAddress: '',
    logLevelId: 20,
    objectId: objectId ?? uuid(),
    pageUrl: pageUrl,
    requestData: JSON.stringify(data),
    shortMessage: message,
    fullMessage: JSON.stringify(headers),
    additionalinfo1: JSON.stringify(cookies),
    userId: userId,
    userName: userName,
  }

  const url = `${INFRA_LOG_ENDPOINT}/create`
  try {
    const logResult: any = await fetcher({
      url,
      data: logData,
      method: 'POST',
      headers: {
        DomainId: process.env.NEXT_PUBLIC_DOMAIN_ID,
      },
    })
    return logResult
  } catch (error: any) {
    console.log(error, 'err')
    // throw new Error(error.message)
  }
  return null
}

export const isUpiPSP = (key: string) => {
  if (UpiPSPs?.data && UpiPSPs?.data?.length) {
    const upiPSP: any = UpiPSPs.data.find((x: any) =>
      matchStrings(x?.key, key, true)
    )
    if (upiPSP?.key) {
      return true
    }
  }
  return false
}

export const encryptFOJSON = (details: any) => {
  const { data, user, orderDetails, stockCode, productSlug } = details
  return encrypt(
    JSON.stringify({
      address: data,
      userEmail: user?.email,
      //userEmail: user?.email || `${user?.username || user?.telephone}@damensch.com`,
      stockCode: stockCode,
      parentOrderNo: orderDetails?.orderNo,
      orderId: orderDetails?.id,
      customInfo: orderDetails?.customInfo3,
      balanceAmount: orderDetails?.balanceAmount?.raw?.withTax,
      paidAmount: orderDetails?.paidAmount?.raw?.withTax,
      orderAmount: orderDetails?.grandTotal?.raw?.withTax,
      productSlug: productSlug,
      userNumber: user?.username || user?.telephone,
    })
  )
}

export const strVal = (val: string): string => {
  if (val && val?.length > 0) {
    return val?.trim()?.toLowerCase()
  }
  return ''
}

export const getUPIAppBundleId = (key: string) => {
  if (UpiPSPs?.data && UpiPSPs?.data?.length) {
    const upiPSP: any = UpiPSPs.data.find((x: any) =>
      matchStrings(x?.key, key, true)
    )
    if (upiPSP?.appBundle) {
      let type = new MobileDetect(window?.navigator.userAgent)
      if (type.os() === 'iOS') {
        return upiPSP?.appBundle?.ios
      } else if (type.os() === 'AndroidOS') {
        return upiPSP?.appBundle?.android
      }
    }
  }
  return ''
}

export const upiPSP = (vpa: string) => {
  const handlerSuffix = vpa?.substring(vpa?.lastIndexOf('@'))
  if (UpiPSPs?.data && UpiPSPs?.data?.length) {
    const upiPSP: any = UpiPSPs.data.find((x: any) =>
      matchStrings(x?.handleSuffix, handlerSuffix, true)
    )
    return upiPSP
  }
  return null
}

export const upiSpriteCss = (vpa: string) => {
  const psp: any = upiPSP(vpa)
  if (psp) {
    return psp?.spriteCss ?? ''
  }
  return ''
}

export const upiKey = (vpa: string) => {
  const psp: any = upiPSP(vpa)
  if (psp) {
    //return `${psp?.key}_${psp?.name}` ?? "";
    return psp?.key
  }
  return ''
}

export const upiType = (vpa: string) => {
  const psp: any = upiPSP(vpa)
  if (psp) {
    //return `${psp?.key}_${psp?.name}` ?? "";
    return `${vpa}_${psp?.name}` || ''
  }
  return ''
}

export const upiName = (vpa: string) => {
  const psp: any = upiPSP(vpa)
  if (psp) {
    return psp?.name ?? ''
  }
  return ''
}

export const sanitizeData = (data: any) => {
  if (data) {
    return data
  }
  return ''
}

export const fetchUser = async (userId: string, isChannelStore?: boolean) => {
  try {
    const { data: getCustomerResult }: any = await axios.post(
      NEXT_GET_CUSTOMER,
      {
        id: userId,
        isChannelStore,
      }
    )
    return getCustomerResult
  } catch (error) {
    console.log(error, 'err')
  }
  return undefined
}

export const getDefaultPaymentMethod = () => {
  const prefPayment = localStorage?.getItem(LocalStorage.Key.PREFERRED_PAYMENT)
  if (prefPayment) {
    return JSON.parse(prefPayment)
  }
  return ''
}

export const setDefaultPaymentMethod = (customer: any) => {
  let preferredPaymentMethod: any = undefined
  if (customer && customer?.defaultPaymentMethod) {
    preferredPaymentMethod = getPreferredPaymentMethod(customer)
  }

  //console.log(preferredPaymentMethod)
  if (preferredPaymentMethod !== undefined) {
    localStorage.setItem(
      LocalStorage.Key.PREFERRED_PAYMENT,
      JSON.stringify(preferredPaymentMethod)
    )
  } else {
    localStorage.setItem(LocalStorage.Key.PREFERRED_PAYMENT, '')
  }
}

export const sanitizeBase64 = (base64: string) => {
  if (base64) {
    return base64
      ?.replace('data:image/png;base64,', '')
      ?.replace('data:image/jpeg;base64,', '')
  }
  return ''
}

export const getDeliveryAddress = () => {
  const address = localStorage?.getItem(LocalStorage.Key.DELIVERY_ADDRESS)
  if (address) {
    return JSON.parse(address)
  }
  return null
}

export const setDeliveryAddress = (address: any) => {
  localStorage.setItem(
    LocalStorage.Key.DELIVERY_ADDRESS,
    JSON.stringify(address)
  )
}

export const resetBasket = async (setBasketId: any, generateBasketId: any) => {
  Cookies.remove('sessionId')
  setSessionIdCookie()
  Cookies.remove('basketId')
  const generatedBasketId = generateBasketId()
  if (setBasketId) {
    setBasketId(generatedBasketId)
  }
}

export const resetStoreBasket = async (
  setStoreBasketId: any,
  generateBasketId: any
) => {
  Cookies.remove('sessionId')
  setSessionIdCookie()
  Cookies.remove('storeBasketId')
  const generatedStoreBasketId = generateBasketId()
  if (setStoreBasketId) {
    setStoreBasketId(generatedStoreBasketId)
  }
}

export const validateAddToCart = (productId: string, cartItems: any) => {
  if (cartItems?.lineItems && cartItems?.lineItems?.length) {
    const findLineItem = cartItems?.lineItems?.find((x: any) =>
      matchStrings(x?.productId, productId, true)
    )
    if (findLineItem) {
      // return findLineItem?.qty != 5
      return findLineItem?.qty <= 5
    }
  }
  return true
}

export const openWindow = (url: string) => {
  if (url) {
    let container = document.getElementsByTagName('body')[0]
    if (container) {
      let lnk = document.createElement('a')
      lnk.setAttribute('target', '_blank')
      lnk.setAttribute('href', url)
      lnk.innerText = ''
      lnk.setAttribute('class', 'tmp-redirect-url')
      container.appendChild(lnk)
      setTimeout(() => {
        lnk.click()

        lnk?.parentElement?.removeChild(lnk)
      }, 20)
    }
  }
}

export const obfuscateHostName = (hostname: string) => {
  if (hostname?.length > 3) {
    return `gandalf${hostname.substring(hostname.length - 3)}`
  }
  return ''
}

export const getLineItemSKU = (attributesJson: string) => {
  if (attributesJson) {
    let attributes: any = {}
    try {
      attributes = JSON.parse(attributesJson)
      if (attributes?.SKU) {
        return attributes?.SKU
      }
    } catch (err) {}
  }
  return ''
}

export const getLineItemColorName = (imageUrl: string) => {
  if (imageUrl) {
    const name = imageUrl.substring(imageUrl.lastIndexOf('/') + 1)
    if (name) {
      const arrParts = name.split('_')
      if (arrParts?.length > 1) {
        return arrParts[1]
      }
    }
  }
  return ''
}

export const isCartAssociated = (cartItems: any) => {
  if (cartItems?.userId && cartItems?.userId !== EmptyGuid) {
    return true
  }
  return false
}

export const getCampaignInfo = (utmInfo: string) => {
  let CustomInfo1 = ''
  if (utmInfo) {
    const jsonValue: any = tryParseJson(utmInfo)
    if (jsonValue?.utm_medium) {
      const json = {
        formatted: [jsonValue],
      }
      CustomInfo1 = JSON.stringify(json)
    }
  }
  return CustomInfo1
}

export const getRtoInfo = (goKwikOrdRes: any) => {
  let CustomInfo = ''
  const codStateEncrypted = localStorage?.getItem(LocalStorage.Key.COD_STATE) ?? '';
  const codState: any = codStateEncrypted ? tryParseJson(decrypt(codStateEncrypted)) : {};
  const json = {
    request_id: codState?.request_id || EmptyString,
    risk_flag: codState?.risk_flag || EmptyString,
    gokwik_oid: goKwikOrdRes?.data?.gokwik_oid || EmptyString,
    moid: goKwikOrdRes?.data?.moid || EmptyString
  }
  CustomInfo = JSON.stringify(json)
  return CustomInfo
}

export const getCampaignInfoFromAppsFlyer = (utmInfo: string) => {
  let CustomInfo1 = ''
  if (utmInfo) {
    const jsonValue: any = tryParseJson(utmInfo)
    if (jsonValue) {
      const json = {
        formatted: [jsonValue],
      }
      CustomInfo1 = JSON.stringify(json)
    }
  }
  return CustomInfo1
}

export const getCommonAppsFlyerValue = async () => {
  const eventValue: any = {}
  const userDetail: any = JSON.parse(
    localStorage?.getItem('user') || JSON.stringify('')
  )
  let mobile256SHA = ''
  if (userDetail?.userId) {
    const mobileNumber =
      userDetail?.mobile || userDetail?.telephone || userDetail?.username
    mobile256SHA = getSHA256Hash(mobileNumber)
    localStorage.setItem('mobile256SHA', mobile256SHA)
  } else if (localStorage?.getItem('mobile256SHA')) {
    mobile256SHA = localStorage?.getItem('mobile256SHA') || ''
  } else {
    // 8byte equal to 16character
    const randomBytes = []
    for (let i = 0; i < length; i++) {
      randomBytes.push(
        Math.floor(Math.random() * 256)
          .toString(16)
          .padStart(2, '0')
      )
    }

    const randomNumber = randomBytes.join('')
    mobile256SHA = randomNumber
    localStorage.setItem('mobile256SHA', randomNumber)
  }
  ;(eventValue[AppsFlyerParams.AFEventParamCustomerUserId] = mobile256SHA),
    (eventValue[AppsFlyerParams.AFEventParamContentType] = 'Online store'),
    (eventValue[AppsFlyerParams.AFEventParamCurrency] = 'INR'),
    (eventValue[AppsFlyerParams.AFEventParam1] = userDetail?.userId
      ? true
      : false)
  if (userDetail?.userId) {
    eventValue[AppsFlyerParams.AFEventParam2] =
      (userDetail?.mobile || userDetail?.telephone || userDetail?.username) ??
      ''
  }
  return eventValue
}

export enum CURRENT_PAGE {
  PLP = 'PLP',
  PDP = 'PDP',
  SEARCH = 'Search',
  WISHLIST = 'Wishlist',
  MY_ACCOUNT = 'My Account',
  CHECKOUT = 'Checkout',
  CART = 'Cart',
  HOME = 'Home',
  REFERRAL = 'Referral',
  VIEW_COUPON = 'View Coupon',
  PAYMENT = 'Payment',
  ADDRESS_BOOK = 'My Saved Address',
  MY_ORDERS = 'My Orders',
  ORDER_DETAILS = 'Order Details',
  RETURN_ORDER = 'Return Order',
  EXCHANGE_ORDER = 'Exchange Order',
  FREE_GIFT = 'Free Gift Select',
  THANK_YOU = 'Thank You',
  LANDING_PAGE = 'Landing Page',
  SEARCH_PAGE = 'Search Page',
}

const getCurrentPageName = () => {}

export const getCurrentPage = (previousPath?: string) => {
  if (typeof window !== 'undefined') {
    let page = previousPath ?? window?.location?.href
    let currentPage: CURRENT_PAGE | undefined = undefined
    if (page.includes('#freeGift')) {
      currentPage = CURRENT_PAGE.FREE_GIFT
    } else if (page.includes('#referralOpen') || page.includes('/referral')) {
      currentPage = CURRENT_PAGE.REFERRAL
    } else if (page.includes('#couponopen')) {
      currentPage = CURRENT_PAGE.VIEW_COUPON
    } else if (page.includes('/cart') || page.includes('#cartopen')) {
      currentPage = CURRENT_PAGE.CART
    } else if (page.includes('#search')) {
      currentPage = CURRENT_PAGE.SEARCH
    } else if (page.includes('/checkout/payment/')) {
      currentPage = CURRENT_PAGE.PAYMENT
    } else if (page.includes('/men')) {
      currentPage = CURRENT_PAGE.PLP
    } else if (page.includes('/pages/')) {
      currentPage = CURRENT_PAGE.LANDING_PAGE
    } else if (page.includes('/p')) {
      currentPage = CURRENT_PAGE.PDP
    } else if (page.includes('/my-account/wishlist')) {
      currentPage = CURRENT_PAGE.WISHLIST
    } else if (page.includes('/my-account/address-book')) {
      currentPage = CURRENT_PAGE.ADDRESS_BOOK
    } else if (page.includes('/my-account/orders?orderId=')) {
      currentPage = CURRENT_PAGE.ORDER_DETAILS
    } else if (page.includes('/my-account/orders')) {
      currentPage = CURRENT_PAGE.MY_ORDERS
    } else if (page.includes('/my-account/return-order')) {
      currentPage = CURRENT_PAGE.RETURN_ORDER
    } else if (page.includes('/my-account/exchange-order')) {
      currentPage = CURRENT_PAGE.EXCHANGE_ORDER
    } else if (page.includes('/my-account')) {
      currentPage = CURRENT_PAGE.MY_ACCOUNT
    } else if (page.includes('/checkout')) {
      currentPage = CURRENT_PAGE.CHECKOUT
    } else if (page.includes('/thank-you')) {
      currentPage = CURRENT_PAGE.THANK_YOU
    } else if (page.includes('/search/')) {
      currentPage = CURRENT_PAGE.SEARCH
    } else {
      currentPage = CURRENT_PAGE.HOME
    }
    return currentPage
  }

  return undefined
}

export const getCurrentUTCString = () => {
  const now = new Date()
  const year = now.getUTCFullYear()
  const month = String(now.getUTCMonth() + 1).padStart(2, '0')
  const day = String(now.getUTCDate()).padStart(2, '0')
  const hours = String(now.getUTCHours()).padStart(2, '0')
  const minutes = String(now.getUTCMinutes()).padStart(2, '0')
  const seconds = String(now.getUTCSeconds()).padStart(2, '0')
  const milliseconds = String(now.getUTCMilliseconds()).padStart(3, '0')

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}+00:00`
}
// export const listDirsRecursive = (dir: string, callback: Function) => {
//   fs.readdirSync(dir).forEach((f) => {
//     let dirPath = path.join(dir, f)
//     let isDirectory = fs.statSync(dirPath).isDirectory()
//     isDirectory
//       ? listDirsRecursive(dirPath, callback)
//       : callback(path.join(dir, f))
//   })
// }

export const getProductsWithKey = (cartItems: any, key = 'Product_name') => {
  const products = cartItems?.lineItems?.reduce((acc: any, cur: any) => {
    acc = {
      ...acc,
      [cur.name]: JSON.stringify({
        Product_name: cur?.name || '',
        Product_ID: cur?.stockCode || '',
        Price: cur?.price?.raw?.withTax || '',
        Product_color:
          getLineItemColorName(
            cur?.variantProducts
              ?.find((x: any) => x?.stockCode === cur?.stockCode)
              ?.variantAttributes?.find(
                (x: any) => x?.fieldCode === 'global.colour'
              )?.fieldValue
          ) || '',
        Product_category: cur?.categoryItems[1]?.categoryName || '',
        Product_category_2: cur?.categoryItems[2]?.categoryName || '',
        Quantity: cur?.qty || '',
        Product_size:
          cur?.variantProducts
            ?.find((x: any) => x?.stockCode === cur?.stockCode)
            ?.variantAttributes?.find(
              (x: any) => x?.fieldCode === 'clothing.size'
            )?.fieldValue || '',
      }),
    }
    return acc
  }, {})
  return products
}

export const openNewCartPage = (
  openCart: Function,
  isFromCartIcon: string = ''
) => {
  if(isFromCartIcon){
    localStorage.setItem(LocalStorage.Key.CART_OPEN_FROM, isFromCartIcon)
  } else {
    localStorage.removeItem(LocalStorage.Key.CART_OPEN_FROM)
  }
  if (isMobileOnly) {
    Router?.push('/cart')
    localStorage.setItem('redirectPreCartRoute', Router?.asPath)
  } else {
    const checkIfCartUrl =
      window?.location?.href?.includes('/cart') ||
      window?.location?.href?.includes('#cartopen')

    if (!checkIfCartUrl) {
      Router?.push(
        { pathname: `${window?.location?.href}#cartopen` },
        `${window?.location?.href}#cartopen`,
        { shallow: true, scroll: false }
      )
      openCart()
    }
  }
  // Router.push('/cart');
}

export const countdownTimerFunction = (
  time: any,
  afterCompletionFn: any,
  setTimerValue: any
) => {
  let interval = time * 1000
  const newDate: any = new Date()
  localStorage.timerEndTime = +newDate + interval

  const reset = () => {
    // localStorage.timerEndTime = +new Date + interval;
  }

  if (!localStorage.timerEndTime) {
    reset()
  }

  const timerInterval = setInterval(() => {
    const newDate: any = new Date()
    let remaining = localStorage.timerEndTime - newDate
    if (remaining >= 0) {
      // setTimerValue(Math.floor( remaining / 1000 ))
      setItem('countdownTimer', Math.floor(remaining / 1000))
      if (remaining === 0) {
        if (afterCompletionFn) {
          afterCompletionFn()
        }
        removeItem('countdownTimer')
        clearInterval(timerInterval)
      }
    } else {
      // reset();
      if (afterCompletionFn) {
        afterCompletionFn()
      }
      removeItem('countdownTimer')
      clearInterval(timerInterval)
    }
  }, 100)
}

export const openLoginView = (asPath: any) => {
  Router?.push(
    {
      pathname: `${asPath}#loginOpen`,
    },
    `${asPath}#loginOpen`,
    {
      shallow: true,
      scroll: false,
    }
  )
  setSessionItem('sidebarType', '')
}

interface IItemCategories {
  category: string
  category2: string
}

export const getCategories = (item: any): IItemCategories => {
  const item_categories: IItemCategories = {
    category: '',
    category2: '',
  }
  if (item?.categoryItems?.length) {
    item_categories.category2 = item?.categoryItems[0]?.categoryName
    item_categories.category =
      item?.categoryItems[item?.categoryItems?.length - 1]?.categoryName
  } else if (item?.classification) {
    item_categories.category2 = item?.classification?.category
    item_categories.category = item?.classification?.mainCategoryName
  } else if (item?.mappedCategories?.length) {
    item_categories.category2 = item?.mappedCategories[0]?.categoryName
    item_categories.category =
      item?.mappedCategories[item?.mappedCategories?.length - 1]?.categoryName
  }
  return item_categories
}

export const getColor = (item: any): string => {
  let color: string = ''
  if (item?.customAttributes?.length) {
    color = item?.customAttributes?.find(
      (x: any) => x?.key === 'product.colours'
    )?.valueText
  } else if (item?.attributes?.length) {
    color = item?.attributes?.find(
      (x: any) => x?.key === 'product.colours'
    )?.valueText
  }
  return color
}

export const getColorAndSize = (item: any): string => {
  let color: string = '',
    size: string = ''
  if (item?.customAttributes?.length) {
    color = item?.customAttributes?.find(
      (x: any) => x?.key === 'product.colours'
    )?.valueText
    size = item?.customAttributes?.find(
      (x: any) => x?.key === 'clothing.size'
    )?.valueText
  } else if (item?.attributes?.length) {
    color = item?.attributes?.find(
      (x: any) => x?.key === 'product.colours'
    )?.valueText
    size = item?.attributes?.find(
      (x: any) => x?.key === 'clothing.size'
    )?.valueText
  }
  if (color && size) {
    return `${color} and ${size}`
  }
  return ''
}

export const getAppsFlyerUtmFromCookies = () => {
  const utm = Cookies.get(UtmAppsFlyerCookieKey)
  let CustomInfo2 = ''
  if (utm) {
    CustomInfo2 = getCampaignInfoFromAppsFlyer(utm)
  }
  let parsedData: any = {}
  const utmData = {
    dynamicLink: '',
  }
  if (CustomInfo2 !== '') {
    parsedData = JSON.parse(CustomInfo2)
    if (parsedData?.formatted?.length) {
      utmData.dynamicLink =
        parsedData?.formatted[parsedData?.formatted.length - 1].dynamicLink
    }
  }
  return utmData
}

export const getAppsFlyerDynamicLinkFromCookiesOnPageReLoad = (): string => {
  const navigationEntries = performance.getEntriesByType('navigation')
  if (navigationEntries.length > 0) {
    const navigationEntry: IPerformanceEntry = navigationEntries[0]
    if (navigationEntry?.type && navigationEntry.type === 'reload') {
      const utmData: any = getAppsFlyerUtmFromCookies()
      return utmData.dynamicLink ?? ''
    }
    return ''
  }
  return ''
}

export const getAppsFlyerDynamicLinkFromCookies = (): string => {
  const utmData: any = getAppsFlyerUtmFromCookies()
  return utmData.dynamicLink ?? ''
}

export const isShowTopStrip = (): boolean => {
  let isShow = false
  if (typeof window !== 'undefined') {
    let currentPage = window?.location?.href?.replace(
      window?.location?.search,
      ''
    )
    let origin = `${window?.location.origin}/`
    if (currentPage === origin) {
      isShow = true
    } else if (currentPage.includes('/search')) {
      isShow = true
    } else if (currentPage.includes('/men/')) {
      isShow = true
    } else if (currentPage.includes(`${window?.location?.origin}/p/`)) {
      isShow = true
    } else if (currentPage.includes('/blog')) {
      isShow = true
    } else if (currentPage.includes('/faq')) {
      isShow = true
    }
    return isShow
  }
  return isShow
}

export const getDeviceInfo = () => {
  const deviceInfo = getItem(LocalStorage.Key.DEVICE_INFO_LOCAL_STORAGE)
  return deviceInfo ?? ''
}

export const getImageNameFromURL = (url?: string): string => {
  const name = url?.match(/.*\/(.*)$/)?.[1] ?? ''
  return name ?? ''
}

export const checkIsLoadedOnMobile = (): boolean => {
  const regex = /Mobi|Android|iPhone/i
  if (typeof navigator === 'undefined') {
    return false
  }

  return regex.test(navigator.userAgent)
}

export const containsOnlyNumbers = (inputString: string) => {
  for (let i = 0; i < inputString.length; i++) {
    if (isNaN(parseInt(inputString[i]))) {
      return false // If a non-number character is found, return false
    }
  }
  return true // If all characters are numbers, return true
}

export const scrollToPreviousPosition = (scrollY: any, id: string) => {
  //alert(scrollY)
  if (scrollY) {
    /*let headerHt = 0;
    //   const ctrlHeader: any = document.querySelector("header.nav-product-header");
      const ctrlHeader: any = document.querySelector(id);
      if (ctrlHeader) {
        headerHt = ctrlHeader.clientHeight;
      }*/
    window?.scrollTo(0, scrollY)
  }
}

export const convertDateToTimeStamp = (newDate: any) => {
  const dateObject = new Date(newDate)
  const year = dateObject.getFullYear()
  const month = String(dateObject.getMonth() + 1).padStart(2, '0')
  const day = String(dateObject.getDate()).padStart(2, '0')
  const hours = String(dateObject.getHours()).padStart(2, '0')
  const minutes = String(dateObject.getMinutes()).padStart(2, '0')
  const seconds = String(dateObject.getSeconds()).padStart(2, '0')
  const milliseconds = String(dateObject.getMilliseconds()).padStart(3, '0')
  const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`
  return timestamp
}

export const getExtendedTimeStamp = (timeStampVal: any, daysVal: number) => {
  const addDays = (date: any, days: any) => {
    const dateCopy = new Date(date)
    dateCopy?.setDate(date.getDate() + days)
    return dateCopy
  }
  const date = new Date(timeStampVal)
  const newDate = addDays(date, daysVal)
  const newTimeStamp = convertDateToTimeStamp(newDate)
  return newTimeStamp
}

export const getDomainName = (value: string): string => {
  const regex =
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?(?:l\.)?(?:m\.)?(?:lm\.)?(?:t\.)?([^:\/\n?]+)?(?:.com)/
  const result = value.match(regex)
  if (!result?.length) {
    return ''
  }
  return result[1]
}

export const checkIsEmailValid = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const checkIsPhoneValid = (phone: string): boolean => {
  const regex = /^[7896]\d{9}$/
  return regex.test(phone)
}

type RefObject<T> = React.ForwardedRef<T>

export function mergeRefs<T>(...refs: RefObject<T>[]) {
  return (value: T) => {
    refs.forEach((ref) => {
      if (typeof ref === 'function') {
        ref(value)
      } else if (ref && typeof ref === 'object') {
        ;(ref as React.MutableRefObject<T | null>).current = value
      }
    })
  }
}

export interface Cookies {
  [key: string]: string
}
export const parseCookies = (cookie: string): Cookies => {
  const cookieString = cookie
  const cookies: Cookies = {}

  if (cookieString === '') {
    return cookies
  }

  cookieString.split(';').forEach((cookie) => {
    const [name, value] = cookie.trim().split('=')
    cookies[name] = decodeURIComponent(value)
  })

  return cookies
}

/**
 * Gets size chart data for a product with the given name.
 *
 * @param {string} productName The name of the product.
 *
 * @returns {Promise<object>} A promise that resolves with the size chart data
 * if the product is found. Otherwise, it rejects with an error.
 */
export const getSizeChartData = async (productName: any) => {
  const name = productName ?.toLowerCase() ?.replaceAll(/[&\/\\#, +()@$~%.'":*?<>{}]/g, '-')
  if (productName) {
    try {
      const { data: data } = await axios.get(NEXT_GET_SIZE_CHART, { params: { name, } },)
      return data
    } catch (error: any) {
      return error
      // throw new Error(error)
    }
  }
}

function getSubdomainIndex() {
  const hostname = window.location.hostname
  const parts = hostname.split('.')

  if (parts.length > 2) {
    const subdomain = parts[0]

    const subdomainIndex = parseInt(subdomain, 10)

    if (!isNaN(subdomainIndex)) {
      return subdomainIndex
    }
  }

  return null
}

export const getBrowserIdForFBConversion = () => {
  let subdomain_index = getSubdomainIndex() || 1
  let creation_time = Math.floor(new Date().getTime() / 1000)
  let random_number = crypto.randomBytes(16)

  return `fb.${subdomain_index}.${creation_time}.${random_number}`
}

export const eventIdForFB = () => {
  return new Date().getTime()
}

export const getIpAddressForFBConversion = async () => {
  try {
    const res: any = await axiosTemp.get('https://api.ipify.org/?format=json')
    return res.data.ip
  } catch (error) {
    console.error('Error fetching IP address:', error)
    return ''
  }
}

export const getDateOfBirthHash = async (user: any) => {
  const { dayOfBirth, monthOfBirth, yearOfBirth } = user
  if (dayOfBirth && monthOfBirth && yearOfBirth) {
    return await getSHA256Hash(`${yearOfBirth}${monthOfBirth}${dayOfBirth}`) //YYYYMMDD
  } else {
    return null
  }
}

export const getGenderHash = async (gender: string) => {
  if (gender && ['male', 'female'].includes(gender.toLocaleLowerCase())) {
    return await getSHA256Hash(gender.toLocaleLowerCase().split('')[0])
  } else {
    return null
  }
}

export const returnOrderStatus = (value: any) => {
  switch (value?.trim()) {
    case 'Return Requested':
    case 'ReturnRequested':
      return {
        status: 'Return Request Initiated',
        msg: 'Post return in initiated by User/CS',
        ragStatus: 'green',
        nextPendingStep: ['Return Request Approval'],
      }
    case 'Reject Request':
      return {
        status: 'Return Request Rejected',
        msg: 'Post return in Rejected by CS',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Not Received':
      return {
        status: 'Return Not Received',
        msg: 'Return Not Received',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Approve Request':
      return {
        status: 'Return Request Approved',
        msg: 'Post return in Approved by CS',
        ragStatus: 'green',
        nextPendingStep: ['Return Out for Pickup'],
      }
    case 'Return Out for Pickup':
    case 'OUT_FOR_PICKUP':
      return {
        status: 'Return Out for Pickup',
        msg: 'Return assigned to Courier Partner',
        ragStatus: 'green',
        nextPendingStep: ['Return Pickedup'],
      }
    case 'Return Pickup Rescheduled':
      return {
        status: 'Return Pickup Failed',
        msg: 'Return pickup failed',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Pickedup':
    case 'PICKED_UP':
      return {
        status: 'Return Pickedup',
        msg: '',
        ragStatus: 'green',
        nextPendingStep: ['Return Received'],
      }
    case 'PICKUP_CANCELLED':
      return {
        status: 'Pickup Cancelled',
        msg: '',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Pickup Cancelled':
      return {
        status: 'Return Pickup Cancelled',
        msg: 'Return pickup cancelled by customer',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Received':
      return {
        status: 'Return Received',
        msg: 'Post return is received in Warehouse',
        ragStatus: 'green',
        nextPendingStep: [],
      }
    case 'Return Cant Be Processed':
      return {
        status: 'Return Verification Failed',
        msg: 'Post return is received in Warehouse and return is rejected by CS due to fraud',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'RefundInitiated':
    case 'RefundProcessed':
      return {
        status: 'Refund Initiated',
        msg: 'Post refund is initiated',
        ragStatus: 'green',
        nextPendingStep: [],
      }
    case 'Refund Completed':
      return {
        status: 'Refund Completed',
        msg: 'Post refund is process from Juspay',
        ragStatus: 'green',
        nextPendingStep: [],
      }
    default:
      return {
        status: value,
        msg: '',
        ragStatus: 'green',
        nextPendingStep: [],
      }
  }
}

export const replacementOrderStatus = (value: any) => {
  switch (value?.trim()) {
    case 'Return Requested':
    case 'ReturnRequested':
      return {
        status: 'Exchange Request Initiated',
        msg: 'Post return in initiated by User/CS',
        ragStatus: 'green',
        nextPendingStep: ['Exchange Request Approval'],
      }
    case 'Reject Request':
      return {
        status: 'Exchange Declined',
        msg: 'Post return in Rejected by CS',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Approve Request':
      return {
        status: 'Exchange Request Approved',
        msg: 'Post return in Approved by CS',
        ragStatus: 'green',
        nextPendingStep: ['Return Out for Pickup'],
      }
    case 'Return Out for Pickup':
    case 'OUT_FOR_PICKUP':
      return {
        status: 'Return Out for Pickup',
        msg: 'Return assigned to Courier Partner',
        ragStatus: 'green',
        nextPendingStep: ['Return Pickedup'],
      }
    case 'PICKUP_CANCELLED':
      return {
        status: 'Pickup Cancelled',
        msg: '',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Pickup Rescheduled':
      return {
        status: 'Return Pickup Failed',
        msg: 'Return pickup failed',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Pickup Cancelled':
      return {
        status: 'Return Pickup Cancelled',
        msg: 'Return pickup cancelled by customer',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Return Pickedup':
    case 'PICKED_UP':
      return {
        status: 'Return Pickedup',
        msg: '',
        ragStatus: 'green',
        nextPendingStep: ['Return Received'],
      }
    case 'Return Received':
      return {
        status: 'Return Received',
        msg: 'Post return is received in Warehouse',
        ragStatus: 'green',
        nextPendingStep: ['Product Quality Approval'],
      }
    case 'Return Not Received':
      return {
        status: 'Exchange Rejected',
        msg: 'Post return is not received in Warehouse, manually updated by CS',
        ragStatus: 'red',
        nextPendingStep: [],
      }
    case 'Replacement Order Created':
    case 'ReplacementOrder created':
      return {
        status: 'Replacement Order Created',
        msg: 'Post replacement Order is created',
        ragStatus: 'green',
        nextPendingStep: [],
      }
    default:
      return {
        status: value,
        msg: '',
        ragStatus: 'green',
        nextPendingStep: [],
      }
  }
}

export const removeDomainFromURL = (url: string) => {
  try {
    const urlObject = new URL(url)
    return urlObject.pathname
  } catch (error) {
    // Handle invalid URL or other errors
    console.error('Invalid URL:', url)
    return url
  }
}

export const handleBrokenImageError = (e: any) => {
  e.target.srcset = '/imagePlaceHolder.jpg?w=96'
}

export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export const getReplacementPolicyImages = (obj: PolicyImagesObject) => {
  const src = obj?.value?.toLowerCase()?.replace(/ /g, '')
  const is30Day = src?.includes(DAYS_30)

  if (is30Day) {
    return '30day1sttryguarantee'
  } else {
    return '500day'
  }
}

export const getReturnPolicyImages = (obj: PolicyImagesObject) => {
  const src = obj?.value?.toLowerCase()?.replace(/ /g, '')
  const is15Day = src?.includes(DAYS_15)
  return '15days'
  // if (is15Day) {
  //   return '15days'
  // } else {
  //   return src
  // }
}

export const getlineItemSizeWithoutSlug = (product: any) => {
  const currentUrl = window.location.href
  const urlSearchParams = new URLSearchParams(currentUrl)
  let isChannelStore = null
  if (urlSearchParams.has('channelStore')) {
    isChannelStore = true
  } else {
    isChannelStore = false
  }
  if (isChannelStore && product?.size !== '' && product?.size !== undefined) {
    return product?.size?.toUpperCase()
  } else if (isChannelStore && product?.customAttributes) {
    return product?.customAttributes
      ?.filter((vp: any) => vp?.key === 'clothing.size')[0]
      ?.fieldText?.toUpperCase()
      ?.replaceAll('-', '')
      ?.replaceAll('SIZE', '')
  } else {
    return product?.variantProducts
      ?.filter((vp: any) => vp?.stockCode === product?.stockCode)[0]
      ?.variantAttributes?.filter(
        (va: any) => va?.fieldCode === 'clothing.size'
      )[0]
      ?.fieldValue?.toUpperCase()
      ?.replaceAll('-', '')
      ?.replaceAll('SIZE', '')
  }
}

export const getEligibleReturnReplacementDays = (obj: any) => {
  const eligibilityMatch = JSON.stringify(obj)?.match(/\d+/)
  if (eligibilityMatch) {
    const number = parseInt(eligibilityMatch[0], 10)
    return number
  } else {
    return null
  }
}

export const transformImages = (images: any[]): IImage[] => {
  try {
    if (!images?.length) return []
    const transformImages = images.map((datum) => {
      return {
        image: datum.image || '',
        name: datum.name || '',
        alt: datum.alt,
        tag: datum.tag,
      }
    }) as IImage[]
    return transformImages
  } catch (err) {
    console.error(':::: err ', err)
    return []
  }
}

export const getBroswerName = () => {
  if (typeof window !== 'undefined') {
    if (
      window?.navigator?.userAgent?.indexOf('Edge') > -1 &&
      window?.navigator?.appVersion?.indexOf('Edge') > -1
    ) {
      return 'Edge'
    } else if (
      window?.navigator?.userAgent?.indexOf('Opera') != -1 ||
      window?.navigator?.userAgent?.indexOf('OPR') != -1
    ) {
      return 'Opera'
    } else if (window?.navigator?.userAgent?.indexOf('Chrome') != -1) {
      return 'Chrome'
    } else if (window?.navigator?.userAgent?.indexOf('Safari') != -1) {
      return 'Safari'
    } else if (window?.navigator?.userAgent?.indexOf('Firefox') != -1) {
      return 'Firefox'
    } else if (window?.navigator?.userAgent?.indexOf('MSIE') != -1) {
      //IF IE > 10
      return 'IE'
    } else {
      return 'Unknown'
    }
  }
}

export const extractStoreIdFromString = (str: string) => {
  const regex = /storeId=([^&]+)/
  const match = str?.match(regex)
  var storeId = match ? match[1] : null
  return storeId
}

export const orderIdFromString = (str: string) => {
  const regex = /oId=([^&]+)/
  const match = str?.match(regex)
  var oId = match ? match[1] : null
  return oId
}

export const extractNumberFromHTMLpTag = (str: string) => {
  const match = str.match(/<p>(\d+)<\/p>/)
  const number = match ? match[1] : ''
  return number
}

export const logError = (error: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log('error', error)
  }
}

export const capitalizeWords = (sentence: string) => {
  if (sentence?.length > 0) {
    // Split the sentence into words
    const words = sentence?.split(' ')
    // Capitalize the first letter of each word
    for (let i = 0; i < words?.length; i++) {
      words[i] = words?.[i]?.charAt(0)?.toUpperCase() + words?.[i]?.slice(1)
    }
    // Join the words back into a sentence
    return words?.join(' ')
  } else {
    return ''
  }
}

export const handleTopBannerVisibility = (
  currentPage: CURRENT_PAGE | undefined
) => {
  if (currentPage) {
    if (
      (currentPage === 'Home' && IS_TOP_BANNER_HOME_ENABLED) ||
      (currentPage === 'PDP' && IS_TOP_BANNER_PDP_ENABLED) ||
      (currentPage === 'PLP' && IS_TOP_BANNER_PLP_ENABLED) ||
      (currentPage === 'Landing Page' && IS_TOP_BANNER_LP_ENABLED)
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

export const getKwikPassFields = () => {
  return {
    bc_login_status: (Cookies.get(Cookie.Key.IS_BC_USER) == 'true') ? true : false,
    kp_no_browser: ((Cookies.get(Cookie.Key.LOGIN_POPUP) == 'false') && (localStorage?.getItem(LocalStorage.Key.GOKWIK_TOKEN))) ? 'found' : 'not_found',
    user_type: (Cookies.get(Cookie.Key.IS_EXISTING_USER) == 'true') ? 'existing' :'new',
    bc_user_token : Cookies.get(LocalStorage.Key.userEncryptedDetails) ? 'exist' : 'not_exist',
    login_popup : (Cookies.get(Cookie.Key.LOGIN_POPUP) == 'true') ? 'kp' : 'NA',
    checkout_flow: getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW),
  }
}

export const getMinHeights = ( isOnlyMobile: any, productIdx: any, product: any, products: any, isSlider: any = false ) => {
  const heights = {
    plpmsg1Height: '0px',
    plpmsg3Height: '0px',
  }

  if (!isOnlyMobile) {
    // For non-mobile view, set default heights
    heights.plpmsg1Height = '20px'
    heights.plpmsg3Height = '20px'
    return heights
  }

  if (isSlider) {
    // Iterate over all products to check for 'plpmsg1' or 'plpmsg3' attributes
    const hasPlpMsg1 = products?.results?.some((prod: any) =>
      prod?.attributes?.some((attr: any) => attr.key === 'product.plpmsg1')
    )

    const hasPlpMsg3 = products?.results?.some((prod: any) =>
      prod?.attributes?.some((attr: any) => attr.key === 'product.plpmsg3')
    )

    // Update heights if any product has the respective messages
    if (hasPlpMsg1) heights.plpmsg1Height = '20px'
    if (hasPlpMsg3) heights.plpmsg3Height = '20px'

    return heights
  }

  // Determine paired product index based on the current index's parity
  const pairedProductIdx =
    productIdx % 2 === 0 ? productIdx + 1 : productIdx - 1

  // Group attributes by key for the current and paired product
  const labelGroup1 = groupBy(product?.attributes, 'key')
  const labelGroup2 = groupBy(
    products?.results?.[pairedProductIdx]?.attributes || [],
    'key'
  )

  // Check if either product has 'plpmsg1' or 'plpmsg3' attributes
  const hasPlpMsg1 =
    labelGroup1?.['product.plpmsg1']?.length > 0 ||
    labelGroup2?.['product.plpmsg1']?.length > 0
  const hasPlpMsg3 =
    labelGroup1?.['product.plpmsg3']?.length > 0 ||
    labelGroup2?.['product.plpmsg3']?.length > 0

  // Update heights if messages are present
  if (hasPlpMsg1) heights.plpmsg1Height = '20px'
  if (hasPlpMsg3) heights.plpmsg3Height = '20px'

  return heights
}

/**
 * Returns an array of all keys in an enumeration.
 * @param enumeration The enumeration to retrieve keys from.
 * @returns An array of all keys in the enumeration.
 */
export const allEnumKeys = <T>(enumeration: T): Array<any> => {
  return (Object.keys(enumeration!) as Array<keyof T>)
      .filter(key => isNaN(Number(key)))
      .filter(key => typeof enumeration[key] === "number" || typeof enumeration[key] === "string")
      .map(key => enumeration[key])
  /*.map(key => ({
      id: enumeration[key],
      description: String(key).replace(separatorRegex, ' '),
  }))*/
}

export const createRTOData = (
  cartItems: any, 
  address: any
): any => {
  let totalQty = 0;

  // Map through the line items and calculate total quantities and other details
  const lineItem = cartItems?.lineItems?.map((item: any) => {
    totalQty = totalQty + item?.qty;
    return {
      product_id: item?.productId,
      line_item_id: item?.stockCode,
      sku: item?.sku,
      price: item?.price?.raw?.withoutTax,
      quantity: item?.qty,
      total: item?.totalPrice?.raw?.withoutTax,
    };
  });

  // Prepare the final order data object
  const data = {
    orderData: {
      customer: {},
      order: {
        order_date: getCurrentUTCString(),
        subtotal: cartItems?.subTotal?.raw?.withTax,
        total_line_items: cartItems?.lineItems?.length,
        total_line_items_quantity: totalQty,
        total_discount: cartItems?.discount?.raw?.withTax,
        total: cartItems?.grandTotal?.raw?.withTax?.toFixed(2),
        promo_code: cartItems?.promotionsApplied?.[0]?.promoCode || 'NOPROMO',
        user_type: 'new',
        platform_type: 'custom',
        session: {
          source: 'Organic',
          customer_user_agent: window?.navigator?.userAgent,
        },
        line_items: lineItem,
        shipping_address: {
          first_name: address?.firstName,
          address_1: address?.address1,
          address_2: address?.address2,
          city: address?.city,
          state: address?.state,
          postcode: address?.postCode,
          email: address?.email || 'phone@gokwik.co',
          phone: address?.phoneNo,
        },
      },
    },
  };

  return data;
};

export const getGoKwikOrderData = ( result: any ) => {
  const codStateEncrypted = localStorage?.getItem(LocalStorage.Key.COD_STATE) ?? '';
  const codState: any = codStateEncrypted ? tryParseJson(decrypt(codStateEncrypted)) : {};
  const user = localStorage?.getItem('user') ?? ''
  const userDetails:any = user ? tryParseJson(user) : {}
  const utmData: any = tryParseJson(Cookies.get(UtmCookieKey))
  return {
    request_id: codState?.request_id || EmptyString,
    order: {
      id: result?.orderNo,
      order_date: result?.orderDate,
      source: utmData ? `utm_source=${utmData?.utm_source || EmptyString}&utm_medium=${utmData?.utm_medium || EmptyString}&utm_campaign=${utmData?.utm_campaign || EmptyString}` : EmptyString,
      status: result?.orderStatus?.toLowerCase(),
      total_tax: result?.tax1?.toString(),
      subtotal: result?.subTotal?.raw?.withTax,
      total_line_items_quantity: result?.items?.reduce((sum: number, item: any) => sum + item?.qty, 0),
      total_line_items: result?.items?.length,
      total_discount: result?.discount?.raw?.withTax,
      total: result?.grandTotal?.raw?.withTax?.toString(), // Need to check
      promo_code: result?.promotions?.map((promo: any) => promo?.promoCode)?.join(',') || EmptyString,
      total_shipping: result?.shippingCharge?.raw?.withoutTax.toString(),
      payment_details: { 
        method_id: result?.paymentStatus === 1 ? 'cod' : 'Non_GK_Prepaid'
      },
      billing_address: {
        first_name: result?.billingAddress?.firstName,
        last_name: result?.billingAddress?.lastName?.trim(),
        address_1: result?.billingAddress?.address1,
        address_2: result?.billingAddress?.address2,
        city: result?.billingAddress?.city,
        state: result?.billingAddress?.state,
        postcode: result?.billingAddress?.postCode,
        country: "IN",
        email: userDetails?.email || EmptyString,
        phone: result?.billingAddress?.phoneNo || result?.billingAddress?.mobileNo || getUserNumber(userDetails),
      },
      shipping_address: {
        first_name: result?.shippingAddress?.firstName,
        last_name: result?.shippingAddress?.lastName?.trim(),
        address_1: result?.shippingAddress?.address1,
        city: result?.shippingAddress?.city,
        state: result?.shippingAddress?.state,
        postcode: result?.shippingAddress?.postCode,
        country: "IN",
        email: userDetails?.email || EmptyString,
        phone: result?.shippingAddress?.phoneNo || result?.shippingAddress?.mobileNo || getUserNumber(userDetails),
      },
      line_items: result?.items?.map((item: any) => ({
        product_id: item?.productId,
        name: item?.name,
        sku: item?.stockCode || item?.sku || EmptyString,
        price: item?.listPrice?.raw?.withTax,
        quantity: item?.qty,
        subtotal: item?.listPrice?.raw?.withTax?.toString(), // Need to check
        total: item?.totalPrice?.raw?.withTax?.toString(),
        discount: item?.discountAmt?.raw?.withTax?.toString(),
        item_discount: item?.discountAmt?.raw?.withTax?.toString(), // Need to check
        // item_shipping: result?.shippingCharge?.raw?.withTax, // Check item_shipping logic
        item_edd: result?.dueDate || EmptyString,
        item_cashback: "0", // Need to check
        tax: item?.tax1 || EmptyString,
        product_thumbnail_url: item?.image,
        major_category: item?.categoryItems?.[0]?.parentCategoryName || EmptyString,
        sub_category: item?.categoryItems?.[0]?.categoryName || EmptyString,
        product_url: `${window?.location?.origin}/` + item?.slug,
        source: utmData ? `utm_source=${utmData?.utm_source || EmptyString}&utm_medium=${utmData?.utm_medium || EmptyString}&utm_campaign=${utmData?.utm_campaign || EmptyString}` : EmptyString,
        variant_id: `v_${item?.stockCode}`
      })),
    }
  };
};

export const generateCodState = (response: any, defaultCODvalue?: number) => {
  // commenting it for now
  // if (response?.final_cod === false) {
  //   return {
  //     codValue: null,
  //     showCod: response?.final_cod,
  //     request_id: response?.request_id || EmptyString,
  //     risk_flag: response?.risk_flag || EmptyString,
  //   };
  // } else if (typeof response?.final_cod === 'number') {
  //   return {
  //     codValue: response?.final_cod,
  //     showCod: true,
  //     request_id: response?.request_id || EmptyString,
  //     risk_flag: response?.risk_flag || EmptyString,
  //   };
  // } else {
    return {
      codValue: typeof defaultCODvalue === 'number' ? defaultCODvalue : null,
      showCod: true,
      request_id: response?.request_id || EmptyString,
      risk_flag: response?.risk_flag || EmptyString,
    };
  // }
};

export const getUserType = () => {
  return getSessionItem('isUserAlreadyExist') === 'true' ? "existing" : "new"
}

export const formatAsBcAddress = (addresses: any[], user: any, state: any): any[] => {
  if (!Array.isArray(addresses)) return [];
  return addresses?.map((address:any) => ({
    address1: address?.address1,
    address2: address?.address2,
    city: address?.city ? address?.city : address?._city,
    state: address?.state ? address?.state : address?._state,
    firstName: address?.firstName,
    lastName: address?.lastName ?? EmptyString,
    phoneNo: address?.phoneNo,
    postCode: address?.postCode,
    isDefault: address?.isDefault,
    isConsentSelected: address?.isConsentSelected,
    label: matchStrings(address?.label, 'Other', true) ? address?.otherAddressType : address?.label,
    title: EmptyString,
    userId: user?.userId,
    country: state?.deliveryMethod?.countryCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    countryCode: state?.deliveryMethod?.countryCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
    ...(IS_EMAIL_FIELD_ENABLED ? { email: address?.email } : {}),
  }));
};

export const getPaymentMethodName = (method: string): string => {
  switch (method?.toUpperCase()) {
    case 'CARD':
      return 'Card';
    case 'UPI':
      return 'UPI';
    case 'NB':
      return 'Net Banking';
    case 'WALLET':
      return 'Wallet';
    case 'CODGOKWIK':
      return 'Delivery';
    default:
      return '';
  }
}

export const getNewRelicFields = (payMode: string) => {
  const startTime = new Date().getTime()
  return {
    loginStatus: (Cookies.get(Cookie.Key.IS_BC_USER) == 'true') ? "bc_yes" : "bc_no",
    payMode,
    startTime
  }
}

/**
 * Retrieves a specific value from the Firebase remote configuration stored in localStorage.
 * 
 * @param {string} key - The key whose value needs to be fetched from the remote configuration.
 * @param {boolean} [ignoreCase=false] - A flag to control whether the key comparison is case-sensitive (default is `false` for case-insensitive).
 * 
 * @returns {boolean | null} - Returns:
 *   - The boolean value of the key if it exists.
 *   - `false` if the key does not exist in the configuration or its value is falsy.
 *   - `null` if the function is called in a server environment or the key is invalid.
 */
export const getRemoteConfigAsBoolean = (key: string, ignoreCase: boolean = false): boolean | null => {
  if (typeof window === 'undefined' || !key) return null;

  const item = getItem(LocalStorage.Key.FIREBASE_REMOTE_CONFIG);
  if (ignoreCase && item) {
    const matchedKey = Object?.keys(item)?.find((k) => matchStrings(k, key, true));
    return matchedKey ? item[matchedKey] : false;
  }
  return item?.[key] || false;
};

export const getRemoteConfigAsString = (key: string, ignoreCase: boolean = false): string => {
  if (typeof window === 'undefined' || !key) return CHECKOUT_FLOW_TYPES.KP1;

  const item = getItem(LocalStorage.Key.FIREBASE_REMOTE_CONFIG);
  if (!item) return CHECKOUT_FLOW_TYPES.KP1;

  if (ignoreCase) {
    const matchedKey = Object?.keys(item)?.find((k) => matchStrings(k, key, true));
    return matchedKey ? item[matchedKey] : CHECKOUT_FLOW_TYPES.KP1;
  }

  return item[key] || CHECKOUT_FLOW_TYPES.KP1;
};

export const getAddressSource = (): string => {
  return stringToBoolean(Cookies.get(Cookie.Key.IS_EXISTING_USER)) 
    ? 'Damensch' 
    : getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW) === CHECKOUT_FLOW_TYPES.PRE  
      ? 'GoKwik' 
      : 'kwikpass';
};

interface FetchEDDParams {
  basketId: any;
  user: any;
  shippingAddr: any;
  isChannelStore?: boolean;
}

export const fetchEDDAsync = async ({ 
  basketId, 
  user, 
  shippingAddr, 
  isChannelStore = false 
}: FetchEDDParams ) => {
  try {
    const requestData = {
      model: {
        basketId,
        userId: user?.userId ?? EmptyString,
        shippingPostCode: numberToString(shippingAddr?.postCode)
      },
      ...(isChannelStore && { isChannelStore }),
    };

    const { data } = await axios.post(NEXT_DELIVERABILITY_ETA, requestData)
    return data 
    } catch (error) {
      console.error('err::', error)
    }
}

interface IApplyHandlingFeeParams {
  basketId: any;
  promoCode: string;
  method?: string;
}

export const applyHandlingFee = async ({
  basketId,
  promoCode,
  method = 'apply',
}: IApplyHandlingFeeParams): Promise<void> => {
  try {
    const cartItems = getItem(LocalStorage.Key.CART_ITEMS) || { lineItems: [] }
    const isFeeApplied = cartItems?.additionalCharge?.raw?.withTax > 0
    if(isFeeApplied) return
    const { data }:any = await axios.post(NEXT_APPLY_PROMOTION, {
      basketId,
      promoCode,
      method,
    });
    return data?.result?.basket
  } catch (error) {
    console.error(':: error ', error)
  }
};

export const isItemCodeInCustomInfo4 = (cartItems: any, itemCode: string): boolean => {
  if (!cartItems?.lineItems || cartItems?.lineItems?.length === 0) {
    return false;
  }
  return cartItems?.lineItems?.some((item: any) =>
    item?.customInfo4?.split(',')?.map((code: any) => code?.trim())?.includes(itemCode)
  );
};

export const generateCustomInfo4 = (cart: any, productId: string, itemCode: string): any => {
  if (!cart?.lineItems) {
    cart.lineItems = [];
  }
  const existingItem = cart?.lineItems?.find((item:any) => matchStrings(item?.productId, productId, true));
  let customInfo4 = EmptyString
  if (existingItem) {
    // Convert existing customInfo4 string into an array of item codes
    const codes = existingItem?.customInfo4 
      ? existingItem?.customInfo4?.split(',')?.map((code:any) => code?.trim()) 
      : [];
    if (!codes?.includes(itemCode)) {
      codes?.push(itemCode); // Add new itemCode if not already present
    }
    customInfo4 = codes?.join(','); // Convert array back to comma-separated string
  } else {
    customInfo4 = itemCode 
  }

  return customInfo4;
};
